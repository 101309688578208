import type { FC } from 'react';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import ReactCodeMirror from '@uiw/react-codemirror';
import { wellPilotTheme } from '../utils';
import { linter, lintGutter } from '@codemirror/lint';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { RuleEditorContainer } from './index';
import { useRuleForEditor } from '../hooks';
import filterStore from '../stores/filterStore';
import type { LevelValue, RuleEntry, RuleGroupEntry, RuleStatusValue } from '../types';
import { Close as CloseIcon } from '@mui/icons-material';
import tasksFormStore from '../stores/tasksFormStore';
import timeTableStore from '../stores/timeTableStore';
import { useFormContext } from 'react-hook-form';
import type { BasicFormValues } from './RuleEdit';
import { useNotificationChannels, useRuleGroups } from '../api';

interface RuleScriptDialogProps {
  isOpen: boolean;
  onClose: () => void;
  rule: RuleEntry;
}

const RuleScriptDialog: FC<RuleScriptDialogProps> = ({ isOpen, onClose, rule }) => {
  const { getValues } = useFormContext<BasicFormValues>();

  const {
    name,
    ruleStatus,
    level,
    description,
    groupId,
    notificationChannels,
    notificationLevel,
    facebookAdAccountIds,
  } = getValues();

  const { data: notificationChannelsList } = useNotificationChannels();
  const { data: ruleGroupsList } = useRuleGroups();

  const getTransformedNotificationChannels = () => {
    return notificationChannelsList.filter((nc) => notificationChannels.includes(nc.id));
  };

  const getTransformedGroup = () => {
    return ruleGroupsList.find((rg) => rg.id === Number(groupId));
  };

  const { code, setCode } = useRuleForEditor({
    ...rule,
    name,
    status: ruleStatus as RuleStatusValue,
    group: getTransformedGroup() as unknown as RuleGroupEntry,
    data: {
      ...rule.data,
      filters: filterStore.filterGroups,
      timetable: timeTableStore.timeTableDTO,
      tasks: tasksFormStore.tasksFormValues,
      name,
      description,
      level: level as LevelValue,
      notification_channels: getTransformedNotificationChannels(),
      facebook_ad_account_ids: facebookAdAccountIds.map(String),
      notification_level: notificationLevel,
    },
  });

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          id="rule-script-dialog-title"
        >
          <Typography variant="subtitle1">rule script</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <RuleEditorContainer>
          <ReactCodeMirror
            value={code}
            editable={false}
            theme={wellPilotTheme}
            height={`${window.screen.height * 0.7}px`}
            extensions={[lintGutter(), json(), linter(jsonParseLinter())]}
            onChange={setCode}
          />
        </RuleEditorContainer>
      </Box>
    </Dialog>
  );
};

export default RuleScriptDialog;
