import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import queryString from 'query-string';
import queryKeys from './queryKeys';
import type { QueryConfig } from 'lib/react-query';
import { ApiNetwork, Network } from 'types/shared';
import prefixApiNetwork from 'utils/prefixApiNetwork';

export const getRulesList = async <TData, TParams>(
  { params, apiNetwork }: { params: TParams; apiNetwork: ApiNetwork },
  signal?: AbortSignal
): Promise<TData> => {
  const res = await apiClient.get<TData>(`${apiNetwork}/rules`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  return res.data;
};

interface UseRulesList<TData, TParams> {
  network: Network;
  config?: QueryConfig<typeof getRulesList, TData>;
  params: TParams;
}

export const useRulesList = <TData, TParams>({ network, params, config }: UseRulesList<TData, TParams>) => {
  const apiNetwork = prefixApiNetwork(network);

  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getRulesList<TData, TParams>({ apiNetwork, params }, signal),
    queryKey: queryKeys.byFilterValues<TParams>(`${apiNetwork ? `${apiNetwork}/` : ''}rules`, params),
  });
};
