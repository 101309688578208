import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { RuleEntry, RuleError } from '../types';
import queryKeys from './queryKeys';
import { isAxiosError } from 'axios';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';

interface UpdateRuleSuccessResponse {
  data: unknown;
}

interface UpdateRuleErrorResponse {
  errors: RuleError[];
  message: string;
}

interface UpdateRulePayload
  extends Pick<RuleEntry, 'name' | 'description' | 'status' | 'level' | 'team_id' | 'data' | 'id' | 'project_id'> {
  group_id?: number;
}

type UpdateRuleResponse = UpdateRuleSuccessResponse | UpdateRuleErrorResponse;

export const updateRule = async ({ id, ...body }: UpdateRulePayload): Promise<UpdateRuleResponse> => {
  const res = await apiClient.put<{ data: UpdateRuleResponse }>(`/rules/${id}`, body);
  if (isAxiosError(res)) {
    const error = new Error('Update rule error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data.data;
};

interface UseUpdateRuleOptions {
  config?: MutationConfig<typeof updateRule>;
}

export const useUpdateRule = ({ config }: UseUpdateRuleOptions = {}) => {
  const queryClient = useQueryClient();
  const { onShowErrorAlert } = useEnqueueSnackbar({ delay: 6000 });

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.rule(args[1].id),
        }),
        queryClient.invalidateQueries({
          queryKey: ['rules'],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    onError: (error) => {
      const cause = error.cause as unknown as UpdateRuleErrorResponse;
      cause.message && onShowErrorAlert(cause.message);

      const message = error?.message;
      if (message) {
        onShowErrorAlert(message);
        return;
      }

      const [{ input: invalidValue, loc, msg }] = cause.errors;
      onShowErrorAlert(`Invalid value ${invalidValue} for field ${loc[loc.length - 1]}: ${msg}`);
    },
    mutationFn: updateRule,
  });
};
