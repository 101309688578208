import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { Location } from 'react-router';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useNotificationChannels, useRuleDetails, useUpdateRule } from '../api';
import { useMyAccess } from 'features/users';
import { RuleEditTitle, TabViewContainer } from './RuleEditComponents';
import useScrollIntoTabView from '../hooks/useScrollIntoTabView';
import RuleEditBasicForm from './RuleEditBasicForm';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ruleEditSchema from '../validation/ruleEdit.schema';
import RuleEditFiltersContainer from './RuleEditFiltersContainer';
import { observer, useObserver } from 'mobx-react-lite';
import filterStore from '../stores/filterStore';
import tasksFormStore from '../stores/tasksFormStore';
import { isEmpty, isEqual } from 'lodash-es';
import RuleEditTasksForm from './RuleEditTasksForm';
import RuleScriptDialog from './RuleScriptDialog';
import { useModal } from 'hooks';
import TimeTableContainer from './TimeTableContainer';
import timeTableStore from '../stores/timeTableStore';
import RuleUIFormActionBlock from './RuleUIFormActionBlock';
import { normalizeObject } from '../utils';
import type { LevelValue, NotificationMessage, RuleStatusValue, RuleTypeMutation } from '../types';
import { DiscardChangesDialog } from './index';
import { toJS } from 'mobx';
import { intervalSchema, uniqueTimeTableSchema } from '../validation/timeTable.schema';
import * as yup from 'yup';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { AccessTimeOutlined } from '@mui/icons-material';
import { useCreateRule } from '../api/createRule';
import CircularSpinner from 'components/common/CircularSpinner';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useRuleNavigation from 'hooks/useRuleNavigation';
const RuleUpdatedMessage: FC<{ text: string }> = ({ text }) => {
  return (
    <Typography
      variant="body2"
      sx={(t) => ({
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        borderRadius: t.borderRadius.md,
        p: 1.5,
        backgroundColor: 'rgba(236, 239, 241, 1)',
        color: 'rgba(1, 67, 97, 1)',
      })}
    >
      <AccessTimeOutlined />
      {text}
    </Typography>
  );
};

export interface BasicFormValues {
  name: string;
  project: number;
  ruleStatus: string;
  level: string;
  description: string;
  groupId: string;
  notificationChannels: string[];
  notificationLevel: string;
  notificationMessage: NotificationMessage | null;
  facebookAdAccountIds: string[];
  networkId: number;
  actionTypesIds: number[];
}

interface RuleEditProps {
  mode: RuleTypeMutation;
}

const RuleEdit: FC<RuleEditProps> = observer(({ mode }) => {
  const isModeDuplicate = mode === 'duplicate';
  const isModeEdit = mode === 'edit';
  const { ruleId } = useParams<{ ruleId: string }>();
  const { data: rule } = useRuleDetails({ id: Number(ruleId) });
  const access = useMyAccess({ id: rule.project_id });
  const navigate = useNavigate();
  const { state: stateLocation } = useLocation();
  const { isOpen, open, close } = useModal();
  const { data: notificationChannelsData } = useNotificationChannels();
  const { onShowInfoAlert, onShowAlert } = useEnqueueSnackbar();
  const [isChangesApplied, setIsChangesApplied] = useState(false);
  const { ruleListURL, ruleEditURL } = useRuleNavigation();

  const getNotificationChannelsDTO = (ids: string[]) => {
    return notificationChannelsData.filter((nc) => ids.includes(nc.id));
  };

  const scrollContainerRef = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { tab, handleChangeTab } = useScrollIntoTabView(scrollContainerRef, contentRef);

  const getInitialValues = useMemo(
    () => ({
      name: rule.name,
      project: rule.project_id,
      ruleStatus: rule.status,
      level: rule.level,
      description: rule.description,
      groupId: `${rule.group.id}`,
      facebookAdAccountIds: rule.data.facebook_ad_account_ids.map(String),
      notificationChannels: rule.data.notification_channels.map((n) => n.id),
      notificationLevel: rule.data.notification_level,
      notificationMessage: {
        title: rule.data.notification_message?.title ?? null,
        body: rule.data.notification_message?.body ?? null,
      },
      networkId: rule.network_id,
      actionTypesIds: rule.action_types.map((at) => at.id),
    }),
    [rule]
  );

  const formMethods = useForm<BasicFormValues>({
    defaultValues: getInitialValues,
    // @ts-expect-error type correction
    resolver: yupResolver(ruleEditSchema),
  });

  const {
    handleSubmit,
    reset,
    getFieldState,
    formState: { isDirty },
  } = formMethods;

  const isNameDirty = getFieldState('name').isDirty;

  useEffect(() => {
    formMethods.reset(getInitialValues);

    if (isEmpty(filterStore.filterGroups)) {
      filterStore.setFilterGroups(rule.data.filters);
    }
    if (isEmpty(tasksFormStore.tasksForm)) {
      tasksFormStore.initialTasks(rule.data.tasks);
    }
    if (timeTableStore.isEmpty) {
      if (rule.data.schedule_interval) {
        timeTableStore.setInitialScheduleInterval(rule.data.schedule_interval);
      } else if (rule.data.timetable) {
        timeTableStore.setInitialData(rule.data.timetable);
      }
    }

    return () => {
      filterStore.reset();
      tasksFormStore.reset();
      timeTableStore.reset();
      reset();
      window.history.replaceState({}, ''); // clear location state
    };
  }, [rule]);

  const { mutateAsync: updateRule, isPending: isUpdating } = useUpdateRule({
    config: {
      onSuccess: () => {
        onShowAlert('Changes saved successfully', { delayAlert: 3500 });
        setIsChangesApplied((v) => !v);
      },
    },
  });

  const { mutateAsync: duplicateRule, isPending: isDuplicating } = useCreateRule({
    config: {
      onSuccess: (data) => {
        navigate(ruleEditURL(data.id), { replace: true, state: { skipBlocker: true, isDuplicated: true } });
        onShowAlert('Rule duplicated', { delayAlert: 3500 });
      },
    },
  });

  useEffect(() => {
    if (access && !access.rules.includes(isModeDuplicate ? 'create-to-some' : 'update')) {
      navigate({
        pathname: '/forbidden',
        search: new URLSearchParams({
          from: 'asset',
          action: 'update',
        }).toString(),
      });
    }
  }, [isModeDuplicate, access, navigate]);

  // When the observable changes, React won't recompute the memoized value because it doesn't detect the change.
  // useObserver - create an observer function that will re-run when the observable changes
  const hasEditFormChanges = useObserver(() => {
    const timeTableObj = rule.data.timetable ? timeTableStore.timeTableDTO : timeTableStore.intervalDTO;
    const ruleTimeTableObj = rule.data.timetable ? rule.data.timetable : rule.data.schedule_interval;

    const isAnythingEmpty = [
      isEmpty(filterStore.filterGroups),
      isEmpty(timeTableObj),
      isEmpty(tasksFormStore.tasksFormValues),
    ].some((el) => el);

    if (isAnythingEmpty) {
      return false;
    }

    const isEqualStateForm = [
      isEqual(rule.data.filters, toJS(filterStore.filterGroups)),
      isEqual(rule.data.tasks, toJS(tasksFormStore.tasksFormValues)),
      isEqual(ruleTimeTableObj, toJS(timeTableObj)),
    ];

    // Terms: https://welltech.atlassian.net/wiki/spaces/MA1/pages/4539842574/Product+requirements#1.2.2--Rule-duplicating
    if (isModeDuplicate) {
      return isNameDirty && isEqualStateForm.some((el) => !el);
    }

    return [!isDirty, ...isEqualStateForm].some((el) => !el);
  });

  const blocker = useBlocker(
    ({
      currentLocation,
      nextLocation,
    }: {
      currentLocation: Location;
      nextLocation: Location<{ skipBlocker?: boolean }>;
    }) => {
      const { skipBlocker } = { ...nextLocation.state };
      return !skipBlocker && hasEditFormChanges && currentLocation.pathname !== nextLocation.pathname;
    }
  );

  const isBlocked = blocker.state === 'blocked';

  const getSubmitPayload = ({
    ruleStatus,
    description,
    project,
    groupId,
    level,
    notificationLevel,
    name,
    notificationChannels,
    facebookAdAccountIds,
    notificationMessage,
    networkId,
    actionTypesIds,
  }: BasicFormValues) => {
    const timeArray = timeTableStore.reversedTimetableEntries.map(([k, v]) => v.time);
    if (rule.data.schedule_interval) {
      intervalSchema.validateSync(timeTableStore.intervalDTO);
    } else {
      uniqueTimeTableSchema.validateSync({ data: timeArray });
    }

    const { id, team_id } = rule;

    return {
      level: level as LevelValue,
      team_id,
      id,
      project_id: project,
      name,
      status: ruleStatus as RuleStatusValue,
      description,
      group_id: Number(groupId),
      network_id: networkId,
      action_types_ids: actionTypesIds,
      data: {
        ...rule.data,
        filters: filterStore.filterGroups,
        ...(timeTableStore.scheduleInterval
          ? { schedule_interval: timeTableStore.scheduleInterval }
          : { timetable: timeTableStore.timeTableDTO }),
        tasks: tasksFormStore.tasksFormValues,
        name,
        description,
        level: level as LevelValue,
        notification_channels: getNotificationChannelsDTO(notificationChannels),
        facebook_ad_account_ids: facebookAdAccountIds.map(String),
        notification_level: notificationLevel,
        notification_message:
          notificationMessage?.title || notificationMessage?.body
            ? {
                title: notificationMessage?.title ?? '',
                body: notificationMessage?.body ?? '',
              }
            : null,
      },
    };
  };

  const onSubmit = async (data: BasicFormValues) => {
    try {
      const payload = getSubmitPayload(data);
      if (isModeEdit) {
        const { network_id, action_types_ids, ...restParams } = payload;
        await updateRule(restParams);
        return;
      }

      if (isModeDuplicate) {
        const { id, ...restParams } = payload;
        await duplicateRule({
          ...restParams,
          network_id: rule.network_id,
          action_types_ids: rule.action_types.map((at) => at.id),
        });
        return;
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        onShowInfoAlert(error.message);
      }
    }
  };

  const onDiscard = () => {
    if (isBlocked) {
      blocker.proceed();
    }
    reset();
    JSON.stringify(normalizeObject(rule.data), null, 2);
    close();
    navigate(ruleListURL);
  };

  const onClose = () => {
    if (isBlocked) {
      blocker.reset();
    }
    close();
  };

  const openLogs = () => {
    window.open(rule.logs_s3_folder_url, '_blank');
  };

  if (isDuplicating) {
    return <CircularSpinner title="Duplicating rule" />;
  }

  return (
    <FormProvider {...formMethods}>
      <DiscardChangesDialog isOpen={isOpen || isBlocked} onClose={onClose} onDiscard={onDiscard} />
      <RuleScriptDialog isOpen={isOpen} onClose={close} rule={rule} />
      <RuleUIFormActionBlock
        mode={['update', 'create-to-some']}
        project={{ id: rule.project_id, name: rule.project }}
        isPending={isUpdating || isDuplicating}
        onDiscard={onDiscard}
        hasChanges={hasEditFormChanges}
        onSave={handleSubmit(onSubmit)}
        submitTitle={isModeDuplicate ? 'Duplicate' : 'Save changes'}
      />
      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <RuleEditTitle name={rule.name} status={rule.status} state={rule.state} mode={mode} />
          {isChangesApplied && <RuleUpdatedMessage text="Rule will be updated in 10 minutes or less." />}
          {stateLocation?.isDuplicated && (
            <RuleUpdatedMessage text="New rule has been created. It will be available to perform in 10 minutes or less." />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
              <Tab value={'basics'} label="Basics" />
              <Tab value={'filters'} label="Filters" />
              <Tab value={'tasks'} label="Tasks" />
              <Tab value={'timetable'} label="Timetable" />
            </Tabs>

            <Box>
              <Button sx={{ textDecoration: 'underline', textTransform: 'inherit' }} onClick={openLogs}>
                <OpenInNewIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle2">S3 Logs</Typography>
              </Button>
              <Button sx={{ textDecoration: 'underline', textTransform: 'inherit' }} onClick={open}>
                Rule script
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          ref={scrollContainerRef}
          sx={{ display: 'flex', flexGrow: 1, position: 'relative', overflow: 'auto', mr: -3 }}
        >
          <Box
            ref={contentRef}
            sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', gap: 3, width: '100%', pr: 3 }}
          >
            <TabViewContainer id="basics">
              <RuleEditBasicForm rule={rule} mode={mode} />
            </TabViewContainer>
            {!isEmpty(filterStore.filterGroups) && (
              <TabViewContainer id="filters">
                <RuleEditFiltersContainer />
              </TabViewContainer>
            )}
            <TabViewContainer id="tasks">
              <RuleEditTasksForm />
            </TabViewContainer>
            <TabViewContainer id="timetable">
              <TimeTableContainer scheduledTimezone={rule.data.schedule_timezone} />
            </TabViewContainer>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
});

export default RuleEdit;
