import type { FC } from 'react';
import type React from 'react';
import { useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import timeTableStore from '../stores/timeTableStore';
import RuleEditCheckbox from './RuleEditCheckbox';
import type { TimeTableDays } from '../types';
import RuleTimetableInput from './RuleTimeableInput';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useModal } from 'hooks';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface TimeRowProps {
  time: string;
  id: string;
}

const TimeRow: FC<TimeRowProps> = observer(({ id, time }) => {
  const { onShowInfoAlert } = useEnqueueSnackbar();
  const { open: setIsHovered, close: setIsNotHovered, isOpen: isHovered } = useModal();

  const showToast = useCallback(
    (errors: string[]) => {
      onShowInfoAlert(errors.join(' '));
    },
    [onShowInfoAlert]
  );

  const handleChangeValue = useCallback(
    (value: string): boolean => {
      return timeTableStore.updateTime(id, value, showToast);
    },
    [id, showToast]
  );

  const handleChangeCheckboxValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      timeTableStore.toggleTime(id, event.target.name as TimeTableDays);
    },
    [id]
  );

  const removeTimeRow = () => {
    const result = timeTableStore.deleteTime(id);

    if (!result) {
      onShowInfoAlert(`You can't delete last row`);
    }
  };

  return (
    <Box
      onMouseEnter={setIsHovered}
      onMouseLeave={setIsNotHovered}
      sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
    >
      <Box sx={{ display: 'flex', width: '100px', mr: 2 }}>
        <RuleTimetableInput placeholder="Value" value={time} onChange={handleChangeValue} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pb: 1,
          borderBottom: '1px solid rgba(0, 8, 46, 0.12)',
          width: '90%',
        }}
      >
        {timeTableStore.days.map((d) => (
          <Box key={d} sx={{ width: '50px', display: 'flex', justifyContent: 'center' }}>
            <RuleEditCheckbox
              name={d}
              onChange={handleChangeCheckboxValue}
              value={timeTableStore.checkTimeInDay(id, d)}
              controlLabelSx={{ margin: 0 }}
            />
          </Box>
        ))}
      </Box>
      <Box sx={{ pb: 1, width: '10%', display: 'flex', justifyContent: 'center' }}>
        {isHovered && (
          <IconButton onClick={removeTimeRow}>
            <DeleteOutlineIcon sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
});

export default TimeRow;
