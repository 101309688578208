import { IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Popover } from '@mui/material';
import type { FC, MouseEvent } from 'react';
import { useCallback, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TriggerRuleModal from './TriggerRuleModal';
import { useModal } from 'hooks';
import { useChangeRuleStatus } from '../api/changeStatus';
import type { RuleStateValue } from '../types';
import useAccessModify from '../hooks/useAccessModify';
import { useEmergencyContext } from 'features/emergency';

interface RulesActionListProps {
  ruleName: string;
  ruleId: number;
  ruleState: RuleStateValue;
}

const RulesActionList: FC<RulesActionListProps> = ({ ruleName, ruleId, ruleState }) => {
  const { isEmergency } = useEmergencyContext();
  const { accessRules } = useAccessModify();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { isOpen: isTriggerModalOpen, open: handleOpenTriggerModal, close: handleCloseTriggerModal } = useModal();

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const { mutateAsync: changeRuleStatus, isPending } = useChangeRuleStatus();

  const archiveRule = async () => {
    await changeRuleStatus({ ruleId, status: 'archived' });
  };

  const pauseRule = async () => {
    await changeRuleStatus({ ruleId, status: 'paused' });
  };

  const unpauseRule = async () => {
    await changeRuleStatus({ ruleId, status: 'active' });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'actions-dropdown' : undefined;

  return (
    <>
      <TriggerRuleModal
        ruleId={ruleId}
        ruleName={ruleName}
        handleClose={handleCloseTriggerModal}
        isOpen={isTriggerModalOpen}
      />
      <IconButton sx={{ width: 36, height: 36 }} onClick={handleOpen} aria-describedby={id}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        onClick={handleClose}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        role={undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        hideBackdrop
      >
        <Paper sx={{ width: 220 }}>
          <List>
            {accessRules.pause && (
              <ListItem disablePadding>
                <ListItemButton onClick={pauseRule} disabled={isPending} sx={{ px: 1.5, py: 0.5 }}>
                  <ListItemText primary={'Pause'} />
                </ListItemButton>
              </ListItem>
            )}
            {accessRules.unpause && (
              <ListItem disablePadding>
                <ListItemButton onClick={unpauseRule} disabled={isPending} sx={{ px: 1.5, py: 0.5 }}>
                  <ListItemText primary="Unpause" />
                </ListItemButton>
              </ListItem>
            )}

            {accessRules.archive && (
              <ListItem disablePadding>
                <ListItemButton onClick={archiveRule} disabled={isPending} sx={{ px: 1.5, py: 0.5 }}>
                  <ListItemText primary="Archive" />
                </ListItemButton>
              </ListItem>
            )}
            {accessRules.manually && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleOpenTriggerModal}
                  sx={{ px: 1.5, py: 0.5 }}
                  disabled={ruleState === 'running' || isEmergency}
                >
                  <ListItemText primary="Run manually" />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Paper>
      </Popover>
    </>
  );
};

export default RulesActionList;
