import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import RulesTableHead from './RulesTableHead';
import RulesTableRow from './RulesTableRow';
import useFilterQueryValues from '../hooks/useFilterQueryValues';
import RulesEmptyState from './RulesEmptyState';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { rulesSchema } from '../validation/rules.schema';
import type { Rule, RulesFormData, RulesListParams } from '../types';
import { useCallback, useEffect } from 'react';
import useListParams from 'hooks/useListParams';
import { useRulesList } from '../../api/getRulesList';
import { ListResponse } from 'lib/api';

const RulesTable = () => {
  const {
    paginationRelatedParams: { page, handlePageChange, handleRowsPerPageChange },
    ...params
  } = useFilterQueryValues();
  const { orderBy, setOrderBy, desc, setDesc, tab } = useListParams();

  const handleToggleSort = useCallback(
    (key: string) => {
      if (key === orderBy) setDesc((prevState) => !prevState);
      else {
        setOrderBy(key);
        setDesc(true);
      }
    },
    [orderBy, setOrderBy, setDesc]
  );
  const {
    data: { data: rules, meta },
  } = useRulesList<ListResponse<Rule>, RulesListParams>({ network: 'google', params });

  const formMethods = useForm<RulesFormData>({
    resolver: yupResolver(rulesSchema),
    defaultValues: {
      rules: rules.reduce((collection, r) => {
        return {
          ...collection,
          [r.id]: false,
        };
      }, {}),
      all_rules_chosen: false,
      all_rules_switched: tab === 'active',
    },
  });

  useEffect(() => {
    formMethods.resetField('all_rules_switched', { defaultValue: tab === 'active' });
    formMethods.resetField('rules', {
      defaultValue: rules.reduce((collection, r) => {
        return {
          ...collection,
          [r.id]: false,
        };
      }, {}),
    });
    formMethods.resetField('all_rules_chosen', { defaultValue: false });
  }, [tab, rules, page, params.limit, formMethods]);

  return rules.length ? (
    <FormProvider {...formMethods}>
      <Box
        sx={{ position: 'relative', display: 'flex', flex: 1, flexDirection: 'column', width: '100%', height: '100%' }}
      >
        <Box sx={{ display: 'flex', position: 'relative', flex: 1, width: '100%' }}>
          <TableContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              width: '100%',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Table
              sx={{
                '&': {
                  position: 'relative',
                  zIndex: 1,
                  border: 'none',
                  overflowX: 'auto',
                  width: '100%',
                },
              }}
              stickyHeader
              size="small"
            >
              <RulesTableHead handleToggleSort={handleToggleSort} desc={desc} orderBy={orderBy} tab={tab} />
              <TableBody sx={{ 'tr:last-of-type': { '& td': { border: 0 } } }}>
                {rules.map((r) => (
                  <RulesTableRow key={r.id} rule={r} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ flex: 1, maxHeight: 50, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <TablePagination
            labelRowsPerPage="Rows per page:"
            component="div"
            count={meta.count}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={params.limit}
            sx={(t) => ({
              display: 'flex',
              justifyContent: 'flex-end',
              flexGrow: 1,
              borderTop: `1px solid ${t.palette.grey[300]}`,
            })}
          />
        </Box>
      </Box>
    </FormProvider>
  ) : (
    <RulesEmptyState />
  );
};

export default RulesTable;
