import { Divider, List } from '@mui/material';
import ListItemStyled from 'components/common/ListItemStyled';
import type { FC } from 'react';
import { useChannels } from '../api/getChannels';
import useFilterQueryValues from '../hooks/useFilterQueryValues';
import useListParams from 'hooks/useListParams';

const FiltersChannels: FC = () => {
  const { channel, setChannel } = useListParams();
  const params = useFilterQueryValues();
  const { data } = useChannels({ params });

  return (
    <List disablePadding>
      <ListItemStyled selected={!channel} name={'All'} quantity={data.all} onClick={() => {
        setChannel(undefined);
      }} />
      <Divider sx={{ my: 2 }} />
      {data.channels.map((c) => (
        <ListItemStyled
          key={`${c.id}.${c.name}.${c.quantity}`}
          selected={channel === c.id}
          name={c.name}
          quantity={c.quantity}
          onClick={() => {
            setChannel(c.id);
          }}
        />
      ))}
    </List>
  );
};

export default FiltersChannels;
