import * as yup from 'yup';

const ruleEditSchema = yup.object().shape({
  name: yup.string().required().min(1).label("Can't be empty"),
  project: yup.number().required().label('project'),
  level: yup.string().required().oneOf(['adset', 'ad', 'campaign']).label('level'),
  groupId: yup.string().label('rule group'),
  description: yup.string(),
  notificationChannels: yup.array(),
  notificationLevel: yup.string(),
  notificationMessage: yup
    .object()
    .shape({
      title: yup.string().nullable(),
      body: yup.string().nullable(),
    })
    .nullable(),
  facebookAdAccountIds: yup.array(yup.string()),
  ruleStatus: yup.string().required().oneOf(['paused', 'archived', 'active']),
  networkId: yup.number(),
  actionTypesIds: yup.array(yup.number()),
});

export default ruleEditSchema;
