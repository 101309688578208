import { Box, Button, Grid, Typography } from '@mui/material';
import ReactCodeMirror from '@uiw/react-codemirror';
import { linter, lintGutter } from '@codemirror/lint';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import type { RuleData, RuleStatusValue } from 'features/rules/facebook';
import {
  RuleDetailsBlock,
  wellPilotTheme,
  useRuleDetails,
  useRuleForEditor,
  EditActionBlock,
  isJSONValid,
  normalizeObject,
  useUpdateRule,
  RuleEditorContainer,
  RuleGroupSelect,
  useSyncPlatforms,
  DiscardChangesDialog,
  compareRule,
  mapFlows,
  editRuleSchema,
  RuleFormElementSkeleton,
} from 'features/rules/facebook';
import type { Location } from 'react-router';
import { useBlocker, useNavigate, useParams } from 'react-router';
import Input from '../../components/common/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { Suspense, useEffect, useMemo, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useMyAccess } from 'features/users';
import RadioGroup from '../../components/common/RadioGroup';
import { useModal } from '../../hooks';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ProjectSelect } from '../../features/projects';
import Autocomplete from 'components/common/Autocomplete';
import type { Option } from '../../types/shared';
import { yupResolver } from '@hookform/resolvers/yup';
interface FormData {
  ruleName: string;
  ruleStatus: RuleStatusValue;
  description: string;
  project: number;
  flows?: (number | string)[] | undefined;
  groupId: number;
}

const EditRulePageLegacy = () => {
  const { ruleId } = useParams<{ ruleId: string }>();
  const [isNameFocused, setIsNameFocused] = useState(false);
  const { data: rule } = useRuleDetails({ id: Number(ruleId) });
  const { code, setCode, prettify } = useRuleForEditor(rule);
  const access = useMyAccess({ id: rule.project_id });
  const navigate = useNavigate();
  const { isOpen, open, close } = useModal();

  const formMethods = useForm<FormData>({
    defaultValues: {
      ruleName: rule.name,
      description: rule.description,
      ruleStatus: rule.status,
      project: rule.project_id,
      flows: rule.flows.map((f) => f.id),
      groupId: rule.group.id || 0,
    },
    // @ts-expect-error type correction
    resolver: yupResolver(editRuleSchema),
  });

  const {
    watch,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = formMethods;

  const selectedProject = watch('project');

  const { data: platforms, isLoading } = useSyncPlatforms<Option[]>({
    params: { project_id: selectedProject },
    config: {
      enabled: !!selectedProject,
      select: (data): Option[] => {
        return data.platforms.flatMap(({ list }) => list.map(({ id, name }) => ({ value: id, label: name })));
      },
    },
  });

  const { mutateAsync: update, isPending } = useUpdateRule({
    config: {
      onSuccess: () => {
        navigate(`/rules/details/${rule.id}`, { state: { skipBlocker: true } });
      },
    },
  });

  useEffect(() => {
    if (access && !access.rules.includes('update')) {
      navigate({
        pathname: '/forbidden',
        search: new URLSearchParams({
          from: 'asset',
          action: 'update',
        }).toString(),
      });
    }
  }, [access, navigate]);

  const hasChanges = useMemo(() => {
    return isDirty || !compareRule(rule.data, code);
  }, [code, isDirty, rule.data]);

  const blocker = useBlocker(
    ({
      currentLocation,
      nextLocation,
    }: {
      currentLocation: Location;
      nextLocation: Location<{ skipBlocker?: boolean }>;
    }) => {
      const { skipBlocker } = { ...nextLocation.state };
      return !skipBlocker && hasChanges && currentLocation.pathname !== nextLocation.pathname;
    }
  );

  const isBlocked = blocker.state === 'blocked';

  const onSubmit = async ({ ruleName, ruleStatus, description, flows, project, groupId }: FormData) => {
    const { level, id, team_id } = rule;
    await update({
      level,
      team_id,
      id,
      data: JSON.parse(code) as RuleData,
      project_id: project,
      name: ruleName,
      status: ruleStatus,
      description,
      group_id: groupId,
    });
  };

  const onDiscard = () => {
    if (isBlocked) {
      blocker.proceed();
    }
    reset();
    JSON.stringify(normalizeObject(rule.data), null, 2);
    close();
    navigate('/rules');
  };

  const onClose = () => {
    if (isBlocked) {
      blocker.reset();
    }
    close();
  };

  return (
    <FormProvider {...formMethods}>
      <Grid container sx={{ pb: 16 }}>
        <DiscardChangesDialog isOpen={isOpen || isBlocked} onClose={onClose} onDiscard={onDiscard} />
        <EditActionBlock
          mode={['update']}
          project={{ id: rule.project_id, name: rule.project }}
          isPending={isPending}
          onDiscard={open}
          onSave={handleSubmit(onSubmit)}
          hasChanges={hasChanges}
          isSaveEnabled={hasChanges && isJSONValid(code) && !!selectedProject}
        />
        <Grid xs={1} item>
          <Link to="/rules">
            <ArrowBackIcon sx={(t) => ({ color: t.palette.action.active })} />
          </Link>
        </Grid>
        <Grid item xs={7} sx={{ pr: 5 }}>
          <Typography variant="h3" sx={{ mb: 2.5 }}>
            Edit rule
          </Typography>
          <Box sx={{ mb: 2.5, display: 'flex', flexDirection: 'column', gap: 2.5 }}>
            <Input
              name="ruleName"
              label="Name"
              required
              size="small"
              onBlur={() => {
                setIsNameFocused(false);
              }}
              onFocus={() => {
                setIsNameFocused(true);
              }}
            />
            {isNameFocused && (
              <Box
                sx={(t) => ({
                  display: 'flex',
                  px: 2,
                  py: 1.5,
                  background: t.palette.infoFill.main,
                  borderRadius: 0.5,
                })}
              >
                <ErrorOutlineIcon sx={{ mr: 2, color: 'rgba(2, 136, 209, 1)' }} />
                <Box>
                  <Typography mb={0.5} fontSize={16} fontWeight={700}>
                    Changing the name will result in the creation of a new rule.
                  </Typography>
                  <Typography fontSize={14}>
                    The old rule will be deleted, and it’s logs will be not available.
                  </Typography>
                </Box>
              </Box>
            )}
            <Suspense fallback={<RuleFormElementSkeleton />}>
              <ProjectSelect onlyUsersProjects name="project" label="Project" required />
            </Suspense>
            <Autocomplete
              InputProps={{ disabled: !platforms }}
              options={platforms ?? []}
              isLoading={isLoading}
              allSelectable={false}
              name="flows"
              id="flows"
              label="Flows"
              tooltipTitle="Please select project"
              error={errors.flows?.message}
            />
            <Suspense fallback={<RuleFormElementSkeleton />}>
              <RuleGroupSelect />
            </Suspense>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Rule conditions
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon sx={{ mr: 0.5, color: 'rgba(2, 136, 209, 1)', fontSize: 14 }} />
                <Typography variant="caption">Rule has to be assigned to accounts from your project.</Typography>
              </Box>
            </Box>
            <Button
              sx={{ mb: 1 }}
              size="small"
              variant="contained"
              disabled={!isJSONValid(code)}
              onClick={() => {
                setCode(prettify());
              }}
            >
              Prettify
            </Button>
          </Box>
          <RuleEditorContainer>
            <ReactCodeMirror
              value={code}
              editable
              theme={wellPilotTheme}
              height="90vh"
              extensions={[lintGutter(), json(), linter(jsonParseLinter())]}
              onChange={setCode}
            />
          </RuleEditorContainer>
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 4 }}>
            <Typography variant="subtitle1" sx={{ lineHeight: 2.5 }}>
              Rule status
            </Typography>
            <RadioGroup
              row
              name="ruleStatus"
              options={[
                { value: 'active', label: 'Active' },
                { value: 'paused', label: 'Paused' },
                { value: 'archived', label: 'Archived' },
              ]}
            />
          </Box>
          <RuleDetailsBlock
            flows={rule.flows}
            project={rule.project}
            team={rule.team}
            level={rule.level}
            facebook_ad_account_ids={rule.data.facebook_ad_account_ids}
            action_types={rule.action_types}
          />
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Description
            </Typography>
            <Box sx={{ py: 1.5, borderRadius: 1.5 }}>
              <Input id="description" name="description" multiline />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default EditRulePageLegacy;
