import type { FC } from 'react';
import type { LogDetails } from '../types';
import { Box } from '@mui/system';
import { Divider, Typography } from '@mui/material';
import ReactCodeMirror from '@uiw/react-codemirror';
import wellPilotTheme from 'features/rules/facebook/utils/codeEditorTheme';
import { linter, lintGutter } from '@codemirror/lint';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import RuleEditorContainer from 'features/rules/facebook/components/RuleEditorContainer';
import { normalizeObject } from '../../rules/facebook';
import orderRule from 'features/rules/facebook/utils/orderRule';

type State = Record<string, any>;
interface EditLogViewProps {
  data: LogDetails;
}

interface EditLogSideProps {
  state: State;
}

const getReadableValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.length ? value.map(({ name }: { name: string }) => name).join(', ') : 'Not selected/Not Assigned';
  }

  if (typeof value === 'number' || typeof value === 'string') {
    return value;
  }

  return value?.name || JSON.stringify(value);
};

const mapChanges = (state: State) => {
  return Object.entries(state)
    .reverse()
    .map(([key, value]) => {
      if (key === 'data') {
        return (
          <Box sx={{ width: '90%' }} key={key}>
            <RuleEditorContainer>
              <ReactCodeMirror
                value={JSON.stringify(normalizeObject(orderRule(value)), null, 2)}
                theme={wellPilotTheme}
                editable={false}
                extensions={[lintGutter(), json(), linter(jsonParseLinter())]}
              />
            </RuleEditorContainer>
          </Box>
        );
      }

      return (
        <Box key={key}>
          <Typography variant="subtitle2" sx={{ textTransform: 'capitalize', mb: 2 }}>
            {key}
          </Typography>
          <Typography>{getReadableValue(value)}</Typography>
          <Divider sx={{ mt: 2 }} />
        </Box>
      );
    });
};

const EditLogSide: FC<EditLogSideProps> = ({ state }) => {
  const changedFields = mapChanges(state);

  return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>{changedFields}</Box>;
};

const EditLogView: FC<EditLogViewProps> = ({ data }) => {
  const oldState = data.item_state_old as State;
  const newState = data.item_state_new as State;

  return (
    <Box sx={{ display: 'flex', width: '100%', pt: 2 }}>
      <Box sx={{ width: '50%', pr: 4 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Before
        </Typography>
        <EditLogSide state={oldState} />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ width: '50%', pl: 4 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          After
        </Typography>
        <EditLogSide state={newState} />
      </Box>
    </Box>
  );
};

export default EditLogView;
