import { Suspense, useEffect, useMemo } from 'react';
import type { Location } from 'react-router';
import { useBlocker, useNavigate, useParams } from 'react-router';
import type { RuleData, RuleStatusValue } from '../../features/rules/facebook';
import {
  EditActionBlock,
  editRuleSchema,
  isJSONValid,
  normalizeObject,
  RuleDetailsBlock,
  RuleEditorContainer,
  RuleFormElementSkeleton,
  RuleGroupSelect,
  useRuleDetails,
  useRuleForEditor,
  wellPilotTheme,
} from '../../features/rules/facebook';
import { useMe, useMyAccess } from '../../features/users';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@mui/material';
import Input from '../../components/common/Input';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReactCodeMirror from '@uiw/react-codemirror';
import { linter, lintGutter } from '@codemirror/lint';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import RadioGroup from '../../components/common/RadioGroup';
import { useCreateRule } from 'features/rules/facebook/api/createRule';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DiscardChangesDialog from 'features/rules/facebook/components/DiscardChangesDialog';
import { useModal } from '../../hooks';
import compareRule from 'features/rules/facebook/utils/compareRule';
import { useSyncPlatforms } from '../../features/rules/facebook';
import type { Option } from '../../types/shared';
import { ProjectSelect } from '../../features/projects';
import Autocomplete from '../../components/common/Autocomplete';
import { yupResolver } from '@hookform/resolvers/yup';
import { mapFlows } from '../../features/rules/facebook';

interface FormData {
  ruleName: string;
  ruleStatus: RuleStatusValue;
  description: string;
  project: number;
  flows?: (number | string)[] | undefined;
  groupId?: number | undefined;
}

const DuplicateRulePageLegacy = () => {
  const { ruleId } = useParams<{ ruleId: string }>();
  const { data: rule } = useRuleDetails({ id: Number(ruleId) });
  const { code, setCode, prettify } = useRuleForEditor(rule);
  const { isOpen, open, close } = useModal();
  const { mutateAsync: createRule, isPending } = useCreateRule({
    config: {
      onSuccess: () => {
        navigate(`/rules`, { state: { skipBlocker: true } });
      },
    },
  });
  const access = useMyAccess({ id: rule.project_id });
  const navigate = useNavigate();
  const { data: me } = useMe();

  useEffect(() => {
    if (access && !(access.rules.includes('create') || access.rules.includes('create-to-some'))) {
      navigate({
        pathname: '/rules',
      });
    }
  }, [access, navigate]);

  const formMethods = useForm<FormData>({
    defaultValues: {
      ruleName: rule.name,
      description: rule.description,
      ruleStatus: rule.status,
      project: rule.project_id,
      groupId: rule.group.id || 0,
      flows: rule.flows.map((f) => f.id),
    },
    // @ts-expect-error type correction
    resolver: yupResolver(editRuleSchema),
  });

  const {
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { dirtyFields, isDirty, errors },
  } = formMethods;

  const selectedProject = watch('project');

  const { data: projects, isLoading } = useSyncPlatforms<Option[]>({
    params: { project_id: selectedProject },
    config: {
      enabled: !!selectedProject,
      select: (data): Option[] => {
        return data.platforms.flatMap(({ list }) => list.map(({ id, name }) => ({ value: id, label: name })));
      },
    },
  });

  useEffect(() => {
    if (me?.projects?.length === 1) {
      setValue('project', me.projects[0].id);
    }
  }, [me, setValue]);

  const ruleDataChanged = useMemo(() => !compareRule(rule.data, code), [code, rule.data]);

  const canSave = dirtyFields.ruleName && ruleDataChanged && !!selectedProject;

  const blocker = useBlocker(
    ({
      currentLocation,
      nextLocation,
    }: {
      currentLocation: Location;
      nextLocation: Location<{ skipBlocker?: boolean }>;
    }) => {
      const { skipBlocker } = { ...nextLocation.state };

      return !skipBlocker && (isDirty || ruleDataChanged) && currentLocation.pathname !== nextLocation.pathname;
    }
  );

  const isBlocked = blocker.state === 'blocked';

  const onSubmit = async ({ ruleName, ruleStatus, description, project, flows, groupId }: FormData) => {
    const { level, network_id, action_types, team_id } = rule;
    await createRule({
      level,
      network_id,
      project_id: project,
      team_id,
      action_types_ids: action_types.map((at) => at.id),
      data: JSON.parse(code) as RuleData,
      group_id: groupId,
      name: ruleName,
      status: ruleStatus,
      description,
    });
  };

  const onDiscard = () => {
    if (isBlocked) {
      blocker.proceed();
    }
    reset();
    JSON.stringify(normalizeObject(rule.data), null, 2);
    close();
    navigate('/rules');
  };

  const onClose = () => {
    if (isBlocked) {
      blocker.reset();
    }
    close();
  };

  return (
    <FormProvider {...formMethods}>
      <Grid container sx={{ pb: 3 }}>
        <DiscardChangesDialog isOpen={isOpen || isBlocked} onClose={onClose} onDiscard={onDiscard} />
        <EditActionBlock
          mode={['create', 'create-to-some']}
          project={{ id: rule.project_id, name: rule.project }}
          isPending={isPending}
          onDiscard={open}
          hasChanges={canSave}
          onSave={handleSubmit(onSubmit)}
          isSaveEnabled={!!canSave && isJSONValid(code)}
        />
        <Grid xs={1} item>
          <Link to="/rules">
            <ArrowBackIcon sx={(t) => ({ color: t.palette.action.active })} />
          </Link>
        </Grid>
        <Grid item xs={7} sx={{ pr: 5 }}>
          <Typography variant="h3" sx={{ mb: 2.5 }}>
            Duplicate rule
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, mb: 2.5 }}>
            <Input
              size="small"
              label="Name"
              additionalLabel={
                dirtyFields.ruleName ? null : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ErrorOutlineIcon sx={{ fontSize: 16, mr: 1, color: 'rgba(2, 136, 209, 1)' }} />
                    <Typography variant="caption">
                      There cannot be two rules with the same name and conditions. Make changes to save new rule.
                    </Typography>
                  </Box>
                )
              }
              name="ruleName"
              sx={{ '& .MuiOutlinedInput-root ': { maxHeight: 60 } }}
            />
            <ProjectSelect onlyUsersProjects name="project" label="Project" required />
            <Autocomplete
              isLoading={isLoading}
              InputProps={{ disabled: !projects }}
              options={projects ?? []}
              allSelectable={false}
              name="flows"
              id="flows"
              label="Flows"
              tooltipTitle="Please select project"
              error={errors.flows?.message}
            />
            <Suspense fallback={<RuleFormElementSkeleton />}>
              <RuleGroupSelect />
            </Suspense>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ mr: 1.25 }}>Rule conditions </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ErrorOutlineIcon sx={{ fontSize: 16, mr: 1, color: 'rgba(2, 136, 209, 1)' }} />
                  <Typography variant="caption">Rule has to be assigned to accounts from your project.</Typography>
                </Box>
              </Box>
              <Button
                size="small"
                variant="contained"
                disabled={!isJSONValid(code)}
                onClick={() => {
                  setCode(prettify());
                }}
              >
                Prettify
              </Button>
            </Box>
            <RuleEditorContainer>
              <ReactCodeMirror
                value={code}
                editable
                theme={wellPilotTheme}
                height="90vh"
                extensions={[lintGutter(), json(), linter(jsonParseLinter())]}
                onChange={setCode}
              />
            </RuleEditorContainer>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 4 }}>
            <Typography variant="subtitle1" sx={{ lineHeight: 2.5 }}>
              Rule status
            </Typography>
            <RadioGroup
              row
              name="ruleStatus"
              options={[
                { value: 'active', label: 'Active' },
                { value: 'paused', label: 'Paused' },
                { value: 'archived', label: 'Archived' },
              ]}
            />
          </Box>
          <RuleDetailsBlock
            flows={rule.flows}
            project={rule.project}
            team={rule.team}
            level={rule.level}
            facebook_ad_account_ids={rule.data.facebook_ad_account_ids}
            action_types={rule.action_types}
          />
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 1.5 }}>
              Description
            </Typography>
            <Box sx={{ py: 1.5, borderRadius: 1.5 }}>
              <Input id="description" name="description" multiline />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default DuplicateRulePageLegacy;
