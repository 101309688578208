import { apiClient } from 'lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';
import type { RuleData, RuleEntry } from '../types';

type RequiredFields = Pick<RuleEntry, 'id'>;
type OptionalRuleEntryFields = Partial<Pick<RuleEntry, 'name' | 'status' | 'project_id'>>;
type OptionalRuleDataFields = Partial<Pick<RuleData, 'facebook_ad_account_ids' | 'notification_channels'>>;
interface ExtraFields {
  flow_id: number;
}

export type BulkEditPayload = RequiredFields & OptionalRuleEntryFields & OptionalRuleDataFields & Partial<ExtraFields>;

interface BulkEditPromise {}

export const bulkEdit = async (data: BulkEditPayload[]): Promise<BulkEditPromise> => {
  const res = await apiClient.patch<BulkEditPromise>(`/rules/bulk_edit`, { data });
  if (isAxiosError(res)) {
    const error = new Error('Bulk Edit Error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseBulkEditOptions {
  config?: MutationConfig<typeof bulkEdit>;
}

export const useBulkEdit = ({ config }: UseBulkEditOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['platforms'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['rules'],
        }),
      ]);

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: bulkEdit,
  });
};
