import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import queryKeys from './queryKeys';
import { Network } from 'types/shared';
import prefixApiNetwork from 'utils/prefixApiNetwork';

interface TriggerRuleResponse {
  data: unknown;
}

interface UpdateRulePayload {
  ruleId: number;
}

export const triggerRule = async ({ ruleId }: UpdateRulePayload, url: string): Promise<TriggerRuleResponse> => {
  const res = await apiClient.post<{ data: TriggerRuleResponse }>(`/${url}/${ruleId}/trigger`);
  return res.data.data;
};

interface UseTriggerRuleOptions {
  network: Network;
  config?: MutationConfig<typeof triggerRule>;
}

export const useTriggerRule = ({ network, config }: UseTriggerRuleOptions = { network: 'facebook' }) => {
  const queryClient = useQueryClient();
  const apiNetwork = prefixApiNetwork(network);
  const url = `${apiNetwork ? `${apiNetwork}/` : ``}rules`;

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.rule(args[1].ruleId),
        }),
        queryClient.invalidateQueries({
          queryKey: [url],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: ({ ruleId }) => triggerRule({ ruleId }, url),
  });
};
