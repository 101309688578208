import { Box, Grid } from '@mui/material';
import { Suspense, type FC } from 'react';
import TitlePageStyles from 'components/common/TitlePageStyles';
import Groups from './Groups';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FilterFormData } from '../types';
import { filterSchema } from '../validation/filters.schema';
import { FilterProjects, FilterProjectsSkeleton } from 'features/projects';
import GroupsSkeleton from './GroupsSkeleton';
import { Outlet, useLocation } from 'react-router';
import useListParams from 'hooks/useListParams';
import MetricsEditSkeleton from './MetricsEditSkeleton';
import FiltersPanel from './FiltersPanel';
import MetricCreate from './MetricCreate';
import { isUndefined } from 'lodash-es';
import MetricsTableSkeleton from './MetricsTableSkeleton';

const Metrics: FC = () => {
  const location = useLocation();
  const { q, project, groupId } = useListParams();

  const formMethods = useForm<FilterFormData>({
    resolver: yupResolver(filterSchema),
    defaultValues: {
      project: project ?? 'all_projects',
      q: q ?? '',
      all_metrics: false,
      metrics: {},
      id_metric_details: undefined,
      group_id: !isUndefined(groupId) ? groupId : undefined,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'end', mb: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'end' }}>
          <TitlePageStyles>Metrics</TitlePageStyles>
          <Box>
            <Suspense fallback={<FilterProjectsSkeleton />}>
              <FilterProjects />
            </Suspense>
          </Box>
        </Box>
        <MetricCreate />
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1, pt: 1, justifyContent: 'start', flexDirection: 'column' }}>
        <FiltersPanel />
        <Grid container flex={1}>
          <Suspense fallback={<GroupsSkeleton />}>
            <Groups />
          </Suspense>
          <Grid
            item
            container
            sx={(t) => ({
              flex: 1,
              borderTop: `1px solid ${t.palette.grey[300]}`,
              borderLeft: `1px solid ${t.palette.grey[300]}`,
            })}
          >
            <Suspense
              fallback={location.pathname.includes('edit') ? <MetricsEditSkeleton /> : <MetricsTableSkeleton />}
            >
              <Outlet />
            </Suspense>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default Metrics;
