import { useParams } from 'react-router';
import { Network } from 'types/shared';

const useCurrentNetwork = (): Network | null => {
  const { network } = useParams();

  if (!network) {
    const retainedNetwork = localStorage.getItem('_network') as Network;
    return retainedNetwork as Network;
  }

  return network as Network;
};

export default useCurrentNetwork;
