import { Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import Switch from 'components/common/Switch';
import RuleStatus from './RuleStatus';
import RulesActionList from './RulesActionList';
import type { Rule } from '../types';
import { format, parseISO } from 'date-fns';
import { useMyAccess } from 'features/users';
import { useChangeRuleStatus } from '../api/changeStatus';
import useListParams from 'hooks/useListParams';
import Checkbox from 'components/common/Checkbox';
import { BooleanParam, useQueryParam } from 'use-query-params';
import bulkDuplicateStore from '../stores/bulkDuplicateStore';
import { observer } from 'mobx-react-lite';
import { useRuleNavigation } from '../hooks';

interface RulesTableRowProps {
  rule: Rule;
}
const RulesTableRow: FC<RulesTableRowProps> = observer(({ rule }) => {
  const [flowsOpen] = useQueryParam('flowsOpen', BooleanParam);
  const { ruleEditURL } = useRuleNavigation();
  const access = useMyAccess({ id: rule.project_id });

  const { tab } = useListParams();

  const { mutateAsync: changeStatus } = useChangeRuleStatus();

  const isRuleSwitchActive = rule.status === 'active';
  const isRuleArchived = rule.status === 'archived';

  const handleChangeStatus = async () => {
    await changeStatus({
      ruleId: rule.id,
      status: rule.status === 'active' ? 'paused' : 'active',
    });
  };

  return (
    <TableRow
      onClick={() => {
        open(ruleEditURL(rule.id), '_blank');
      }}
      sx={{
        '& td': {
          p: 1,
        },
        '&:hover': {
          background: 'rgba(41, 121, 255, 0.08)',
        },
      }}
    >
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
        align="center"
        sx={{ pr: '0px !important' }}
      >
        <Checkbox name={`rules.${rule.id}`} controlLabelSx={{ m: 0 }} />
      </TableCell>
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{ pl: '4px !important', pr: '2px !important' }}
      >
        <Tooltip title={isRuleArchived && 'Unarchive to unpause'}>
          <Switch
            checked={isRuleSwitchActive}
            onChange={handleChangeStatus}
            disabled={isRuleArchived || !access?.rules.includes('rule_start-stop')}
            inputProps={{ 'aria-label': 'ant design' }}
          />
        </Tooltip>
      </TableCell>
      <TableCell sx={{ width: 300, maxWidth: flowsOpen ? 300 : 400, pl: '0 !important' }}>
        <Tooltip
          arrow
          title={<Typography sx={{ fontSize: 10, fontWeight: 500 }}>{rule.name}</Typography>}
          sx={{ cursor: 'default' }}
        >
          <Typography
            variant="body2"
            sx={{
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              fontSize: 13,
            }}
          >
            {rule.name}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ maxWidth: 64 }}>
        <RuleStatus status={(rule.status === 'active' ? rule.state : rule.status) as never} />
      </TableCell>
      <TableCell sx={{ maxWidth: 140, width: 140 }}>
        {tab === 'paused' && (
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {rule.paused_at ? format(parseISO(rule.paused_at), 'dd.MM.yy HH:mm') : '-'}
          </Typography>
        )}
        {tab === 'archived' && (
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {rule.archived_at ? format(parseISO(rule.archived_at), 'dd.MM.yy HH:mm') : '-'}
          </Typography>
        )}
        {(tab === 'all' || tab === 'active') && rule.rule_runs[0] && (
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {format(parseISO(rule.rule_runs[0]), 'dd.MM.yy HH:mm')}
          </Typography>
        )}
      </TableCell>
      <TableCell sx={{ maxWidth: 140, width: 140 }}>
        <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
          {rule.next_run ? format(parseISO(rule.next_run), 'dd.MM.yy HH:mm') : '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: 150 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              mr: 1,
              maxWidth: '65%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            variant="body2"
          >
            {rule.group.name ?? 'No data'}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 180 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body2"
            sx={{ mr: 1, maxWidth: '65%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          >
            {rule.flows.length ? rule.flows.map((f) => f.name).join(', ') : 'No data'}
          </Typography>
          {rule.flows.length > 1 && (
            <Tooltip
              arrow
              title={
                <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                  {rule.flows.map((f) => f.name).join(', ')}
                </Typography>
              }
              sx={{ cursor: 'default' }}
            >
              <Typography
                sx={(t) => ({
                  background: 'rgba(35, 52, 112, 0.04)',
                  borderRadius: '50%',
                  px: 1,
                  py: 0.5,
                  color: t.palette.text.secondary,
                })}
                variant="body2"
                color="secondary"
              >
                {rule.flows.length}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell sx={{ maxWidth: 140, width: 140 }}>
        <Typography variant="body2">{rule.level}</Typography>
      </TableCell>
      <TableCell align="right">
        <RulesActionList ruleId={rule.id} ruleName={rule.name} ruleState={rule.state} />
      </TableCell>
    </TableRow>
  );
});

export default RulesTableRow;
