import { Box, Button, CircularProgress } from '@mui/material';
import type { Actions } from 'features/users/hooks/useMyAccess';
import useMyAccess from 'features/users/hooks/useMyAccess';
import type { FC } from 'react';
import type { Project } from '../api/types';

interface EditActionBlock {
  onDiscard: () => void;
  onSave: () => void;
  isSaveEnabled: boolean;
  isPending: boolean;
  hasChanges?: boolean;
  project: Project;
  mode: Extract<Actions, 'update' | 'create' | 'create-to-some'>[];
}
const EditActionBlock: FC<EditActionBlock> = ({
  onDiscard,
  onSave,
  isSaveEnabled,
  isPending,
  hasChanges,
  project,
  mode,
}) => {
  const access = useMyAccess(project);

  // @ts-expect-error because not all Actions can be in mode
  if (!access || !access.rules.some((a) => mode.includes(a))) {
    return null;
  }

  const isSaveBtnDisabled = !isSaveEnabled || isPending;
  const isDiscardBtnDisabled = isPending || !hasChanges;

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1.25,
        borderRadius: 5,
        position: 'fixed',
        right: 24,
        bottom: 40,
        zIndex: 10,
        backgroundColor: 'white',
        p: 1,
        width: 180,
        boxShadow:
          '0px 3px 5px -1px rgba(0, 0, 0, 0.10), 0px 6px 10px 0px rgba(0, 0, 0, 0.07), 0px 1px 18px 0px rgba(0, 0, 0, 0.06)',
      }}
    >
      {isPending ? (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', height: 36, alignItems: 'center' }}>
          <CircularProgress size={28} sx={(t) => ({ color: t.palette.primary.dark })} />
        </Box>
      ) : (
        <>
          <Button
            sx={(t) => ({
              flex: 1,
              border: 'none',
              borderRadius: `${t.shape.borderRadius * 2}px`,
              '&:hover': { border: 'none' },
              px: 1,
            })}
            size="medium"
            color="error"
            variant="outlined"
            disabled={isDiscardBtnDisabled}
            onClick={onDiscard}
          >
            Discard
          </Button>
          <Button
            sx={(t) => ({
              flex: 1,
              border: 'none',
              borderRadius: `${t.shape.borderRadius * 2}px`,
              '&:hover': { border: 'none' },
              px: 0.66,
            })}
            size="medium"
            variant={isSaveBtnDisabled ? 'contained' : 'outlined'}
            disabled={isSaveBtnDisabled}
            onClick={onSave}
          >
            Save
          </Button>
        </>
      )}
    </Box>
  );
};

export default EditActionBlock;
