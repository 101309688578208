import useListParams from 'hooks/useListParams';
import { STATE_VALUES, STATUS_VALUES } from '../constants';
import { usePagination } from 'hooks';
import { useCallback } from 'react';

const useFilterQueryValues = () => {
  const { q, project, statusState, orderBy, desc, tab, channel, groups } = useListParams();
  const { perPage, page, handleRowsPerPageChange, resetPagination, setPerPage, handlePageChange } = usePagination(
    [q, project, statusState, orderBy, desc, groups],
    100
  );

  const initialStatusValue = useCallback(() => {
    const statusValue = statusState ? (statusState as string[]).filter((s) => STATUS_VALUES.includes(s)) : undefined;
    return tab === 'active' && !statusValue?.includes('active') ? [...(statusValue ?? []), 'active'] : statusValue;
  }, [statusState, STATUS_VALUES, tab]);

  return {
    name: q?.length ? q : undefined,
    project_id: project === 'all_projects' ? undefined : parseInt(project),
    status: initialStatusValue(),
    state: statusState ? (statusState as string[]).filter((s) => STATE_VALUES.includes(s)) : undefined,
    channel,
    limit: perPage,
    offset: page * perPage,
    order_by: orderBy,
    desc,
    paginationRelatedParams: {
      page,
      handleRowsPerPageChange,
      resetPagination,
      setPerPage,
      handlePageChange,
    },
  };
};

export default useFilterQueryValues;
