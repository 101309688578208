import { apiClient } from 'lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';
import { RuleData, RuleEntry } from '../types';

export interface BulkDuplicatePayload
  extends Pick<RuleEntry, 'id' | 'name' | 'status' | 'project_id'>,
    Pick<RuleData, 'facebook_ad_account_ids' | 'notification_channels'> {
  flow_id: number;
}

interface BulkDuplicatePromise {}

export const bulkDuplicate = async (data: BulkDuplicatePayload[]): Promise<BulkDuplicatePromise> => {
  const res = await apiClient.post<BulkDuplicatePromise>(`/rules/bulk_duplicate`, { data });
  if (isAxiosError(res)) {
    const error = new Error('Bulk Duplicate Error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseBulkDuplicateOptions {
  config?: MutationConfig<typeof bulkDuplicate>;
}

export const useBulkDuplicate = ({ config }: UseBulkDuplicateOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['platforms'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['rules'],
        }),
      ]);

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: bulkDuplicate,
  });
};
